import { isSandbox } from "app/helpers/helper";

export const config = {
	stores: {
		en: {
			name: "Europe",
			locales: ["en"],
			hash: isSandbox() ? "aqj6dh7j08" : "ih6mv4ftuv",
			phonePrefix: "",
			embeddedCheckoutChannelId: isSandbox() ? 1441131 : 1466516,
		},
		rs: {
			name: "Srbija",
			locales: ["sr-Latn-RS"],
			hash: isSandbox() ? "e4s36vbhcy" : "kd54n9g108",
			phonePrefix: "381",
			embeddedCheckoutChannelId: isSandbox() ? 1441140 : 1398708,
		},
		ba: {
			name: "Bosna i Hercegovina",
			locales: ["bs-BA"],
			hash: isSandbox() ? "pcajoczyvs" : "vk7ymvk79g",
			phonePrefix: "387",
			embeddedCheckoutChannelId: isSandbox() ? 1441125 : 1429372,
		},
		hr: {
			name: "Hrvatska",
			locales: ["hr"],
			hash: isSandbox() ? "mbfl34uec2" : "ew3rm6cbbg",
			phonePrefix: "385",
			embeddedCheckoutChannelId: isSandbox() ? 1441128 : 1434245,
		},
		mk: {
			name: "Северна Македонија",
			locales: ["mk-MK"],
			hash: isSandbox() ? "ww3ax386lk" : "gdhh7k6qlp",
			phonePrefix: "389",
			embeddedCheckoutChannelId: isSandbox() ? 1441135 : 1434243,
		},
		me: {
			name: "Crna Gora",
			locales: ["sr-ME"],
			hash: isSandbox() ? "pcp5nhs5tx" : "53sebp5dze",
			phonePrefix: "382",
			embeddedCheckoutChannelId: isSandbox() ? 1441139 : 1434241,
		},
		al: {
			name: "Shqiperia",
			locales: ["sq_AL"],
			hash: isSandbox() ? "kk1gj7kstx" : "hazmqxqyts",
			phonePrefix: "355",
			embeddedCheckoutChannelId: isSandbox() ? 1665945 : 1666225,
		},
		si: {
			name: "Slovenia",
			locales: ["sl-SI"],
			hash: isSandbox() ? "zqbufnl04b" : "ophvghixcd",
			phonePrefix: "386",
			embeddedCheckoutChannelId: isSandbox() ? 1666221 : 1666836,
		},
		nl: {
			name: "Netherlands",
			locales: ["en"],
			hash: isSandbox() ? "by7rjq6izw" : "kmpqdripmj",
			phonePrefix: "31",
			embeddedCheckoutChannelId: isSandbox() ? 1709961 : 1709928,
		},
		us: {
			name: "United States of America",
			locales: ["en"],
			hash: isSandbox() ? "znai3cjv9p" : "cnvzqb24yx",
			phonePrefix: "1",
			embeddedCheckoutChannelId: isSandbox() ? 1714264 : 1714228,
		},
		
		at: {
			name: "Austria",
			locales: ["de_AT"],
			hash: isSandbox() ? "q5evzdarx1" : "bl2oovmtld",
			phonePrefix: "43",
			embeddedCheckoutChannelId: isSandbox() ? 1717486 : 1717502

		},
	},
};
