import React, { useEffect } from "react";
import { useOnClickOutside } from "usehooks-ts";

import Button from "components/common/ui/Button/Button";
import { NotificationDropdownContainer } from "./NotificationModal.Styled";
import IconBell from "components/common/ui/Icons/IconBell/IconBell";
import { useMatch } from "react-router";

const NotificationModal = () => {
  const pathMatch = useMatch("/:locale/*");
  const [isOpen, setIsOpen] = React.useState(false);
  const [formId, setFormId] = React.useState<string | null>(null);
  const [apiKey, setApiKey] = React.useState<string | null>(null);

  useEffect(() => {
    if (!pathMatch?.params.locale) {
      return;
    }

    try {
      const { REACT_APP_KLAVYIO_FORM_ID: IDS = "{}" } = process.env;
      const parsedIds = JSON.parse(IDS);

      if (parsedIds[pathMatch.params.locale]?.formIds?.priceChange) {
        setFormId(parsedIds[pathMatch.params.locale].formIds.priceChange);
        setApiKey(parsedIds[pathMatch.params.locale].apiKey || null);
      } else {
        console.warn("Klaviyo form ID not found for this locale.");
      }
    } catch (error) {
      console.error("Error parsing Klaviyo IDS configuration:", error);
    }
  }, [pathMatch]);

  useEffect(() => {
    if (!apiKey) return;

    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = `//static.klaviyo.com/onsite/js/klaviyo.js?company_id=${apiKey}`;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey]);

  const handleFormTrigger = () => {
    setIsOpen((prev) => !prev);
  };

  const menuRef = React.useRef(null);
  useOnClickOutside(menuRef, () => setIsOpen(false));

  return (
    <NotificationDropdownContainer className="notification">
      <Button icon={<IconBell />} onClick={handleFormTrigger} />
      {formId && (
        <div
          className="notification-menu"
          style={{ display: isOpen ? "block" : "none" }}
          ref={menuRef}
        >
          <div className={`klaviyo-form-${formId}`}></div>
        </div>
      )}
    </NotificationDropdownContainer>
  );
};

export default NotificationModal;
