import { useRef } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import uuid from "react-uuid";
import { Swiper, SwiperSlide, } from "swiper/react";
// import required modules
import { EffectFade, Swiper as SwiperType, Zoom } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import { Image } from "types/Product";

import IconChevron from "../Icons/IconChevron/IconChevron";
import Link, { LinkData } from "../Link/Link";
import {
	CarouselArrow,
	CarouselWrapper,
	ImageSkeleton,
} from "./Carousel.Styled";
import Skeleton from "../Skeleton/Skeleton";
import LazyLoad from 'react-lazy-load';

type Props = {
	images: Image[];
	link?: LinkData;
	fixed?: boolean;
	highRes?: boolean;
	loading?: boolean;
	onSlideClick?: (event: Event) => void;
};

const Carousel = ({
	images,
	link,
	fixed = false,
	highRes = false,
	loading = false,
	...props
}: Props) => {
	const swiperRef = useRef<SwiperType>();

	const onNavigationClick = (direction: "next" | "prev") => {
		if (!fixed && swiperRef.current) {
			switch (direction) {
				case "next":
					swiperRef.current.slideNext();
					break;
				case "prev":
					swiperRef.current.slidePrev();
					break;
			}
		}
	};

	const getImageUrl = (image: Image | undefined) => {
		if (!image) {
			return "";
		}

		if (highRes) {
			return image.urlOriginal ? image.urlOriginal : image.url;
		} else {
			return !image.urlOriginal ? image.urlOriginal : image.url;
		}
	};

	if (loading) {
		return (
			<CarouselWrapper className="Carousel">
				<ImageSkeleton>
					<Skeleton />
				</ImageSkeleton>
			</CarouselWrapper>
		);
	}

	if (fixed && images) {
		let thumbnail = images.find((image) => image.isDefault);

		if (!thumbnail) {
			thumbnail = images.find((image) => image.urlOriginal);
		}

		return (
			<CarouselWrapper className="Carousel CaroselFixed">
				<Link {...link} onClick={props.onSlideClick}>
					
					<img
						src={getImageUrl(thumbnail)}
						alt={thumbnail && thumbnail.altText}
						draggable={false}
						className="static-image"
					/>
					
				</Link>
			</CarouselWrapper>
		);
	}

	const imagesToSort = [...images];

	return (
		<CarouselWrapper className="Carousel">
			<CarouselArrow
				className="carousel-navigation carousel-navigation--prev"
				onClick={() => onNavigationClick("prev")}
			>
				<IconChevron direction="left" />
			</CarouselArrow>
			<Link {...link} onClick={props.onSlideClick}>
				<LazyLoad height={'auto'} offset={1000}>
				<Swiper
					allowTouchMove
					onBeforeInit={(swiper) => {
						swiperRef.current = swiper;
					}}
					modules={[EffectFade, Zoom]}
					effect={"fade"}
					loop
					zoom={{ maxRatio: 3, minRatio: 1, toggle: true }}
				>
					{imagesToSort
						.sort((x, y) =>
							x.isDefault === true ? -1 : y.isDefault === true ? 1 : 0,
						)
						.map((image, index) => {
							return (
								<SwiperSlide key={uuid()}>
									<div className="swiper-zoom-container">
										<img
											src={getImageUrl(image)}
											alt={image.altText}
											draggable={false}
											{...index > 0 && {
												loading: 'lazy'
											}}
										/>
									</div>
									<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
								</SwiperSlide>
							);
						})
					}
				</Swiper>
				</LazyLoad>
			</Link>
			<CarouselArrow
				className="carousel-navigation carousel-navigation--next"
				onClick={() => onNavigationClick("next")}
			>
				<IconChevron direction="right" />
			</CarouselArrow>
		</CarouselWrapper>
	)
}

export default Carousel
