import { FunctionComponent, useEffect, useState } from "react";

import Link from "components/common/ui/Link/Link";
import LoadingSpinner from "components/common/ui/LoadingSpinner/LoadingSpinner";
import Page from "components/common/ui/Page/Page";
import { PageType } from "ts/enums";
import PathTranslationKey from "utilities/paths";
import { dark } from "utilities/variables/colors";
import { embedCheckout } from "@bigcommerce/checkout-sdk";
import { isIOS } from "react-device-detect";
import { remCalc } from "utilities/styles";
import styled from "styled-components/macro";
import useCart from "hooks/cart/useCart";
import { useLazyGetCartQuery } from "app/api/cart/cartApi";
import useLocaleNavigate from "hooks/reactRouterWrappers/useLocaleNavigate";
import { useSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";

const Title = styled.h2`
	color: ${dark};
	font-size: ${remCalc(20)};
	line-height: ${remCalc(24)};
	font-weight: bold;
	text-align: center;
	margin: 0;
	margin-top: ${remCalc(16)};
	padding: 0 16px;
`;

const SubTitle = styled.h4`
	color: ${dark};
	font-size: ${remCalc(14)};
	line-height: ${remCalc(17)};
	font-weight: normal;
	text-align: center;
	margin: 0;
	margin-bottom: ${remCalc(16)};
	padding: 0 16px;

	a {
		text-decoration: underline;
	}
`;

const Checkout: FunctionComponent = () => {
	const { cart, cartExists, deleteCart } = useCart();
	const { t } = useTranslation();
	const navigate = useLocaleNavigate();

	const [getCart, getCartResult] = useLazyGetCartQuery();

	const [embeddedLoaded, setEmbeddedLoaded] = useState(false);

	const store = useSelector((state) => state.bcStore.store);

	const isNetherlands = store?.code === "nl";

	// We don't embed the checkout for iOS users because of a capacitor domain limitation on iOS.
	// Instead, we redirect them to the checkout page.
	// IIRC it's to do with the URL on iOS being capacitor://www.monaonline.com instead of https://www.monaonline.com,
	// but this should be double checked and documented here.

	// We don't embed the checkout for Netherlands users because the payment method iDEAL's security policies prevent it
	// from being rendered within an iframe, and creating a mechanism to redirect the top level window instead is more
	// complicated and given the short time we have to complete this ticket, we've opted to go with this faster approach.
	// Moreover, the payment method does not provide us a convenient mechanism to trigger the redirection, so it's bound to be
	// more complicated than it needs to be.
	const shouldRedirectToCheckout = isIOS || isNetherlands;

	useEffect(() => {
		if (cart) {
			getCart(cart.id)
				.unwrap()
				.then((res) => {
					if (!store) {
						console.error(
							"No store available. This is not expected at this time",
						);
						return;
					}

					let url = res.redirect_urls.embedded_checkout_url;

					if (res.embed_checkout_jwt) {
						url = `https://${
							new URL(res.redirect_urls.checkout_url).hostname
						}/login/token/${res.embed_checkout_jwt}`;
					}

					if (shouldRedirectToCheckout) {
						return (window.location.href = url);
					}

					if (url)
						embedCheckout({
							containerId: "checkout-app",
							url: String(url),
							onSignOut: () => navigate(t(PathTranslationKey.SIGN_OUT)),
							onComplete: () => deleteCart(),
							onLoad: () => setEmbeddedLoaded(true),
						});
				});

			return;
		}
		if (!embeddedLoaded && !cartExists()) {
			navigate(t(PathTranslationKey.CART));
		}
	}, [cart]);

	return (
		<Page pageType={PageType.DEFAULT}>
			<div id="checkout-app"></div>
			{getCartResult.isLoading || getCartResult.isFetching ? (
				<LoadingSpinner />
			) : shouldRedirectToCheckout ? (
				<>
					<Title>{t("checkout.title")}</Title>
					<SubTitle>
						<Link to={`${String(t(PathTranslationKey.CART))}`}>
							{t("checkout.subtitle")}
						</Link>
					</SubTitle>
				</>
			) : null}
		</Page>
	);
};

export default Checkout;
