import { COOKIE_PRODUCT_GROUP } from "utilities/consts";
import Cookies from "js-cookie";
import InputCheckbox from "components/common/form/InputCheckbox/InputCheckbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Link from "components/common/ui/Link/Link";
import Price from "components/common/ui/Price/Price";
import QuantityChanger from "components/common/form/QuantityChanger/QuantityChanger";
import Skeleton from "components/common/ui/Skeleton/Skeleton";
import { StyledProductCard } from "./AccountProduct";
import Typography from "components/common/ui/Typography/Typography";
import { getProductPathFromURL } from "app/utils/bigcommerceApiUtil";
import { unitOfMeasure } from "components/pdp/PdpDetails/PdpSizeActions/PdpSizeGuide/Measurement/UnitsOfMeasure";
import useCart from "hooks/cart/useCart";
import { useSelector } from "redux/hooks";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";

type Props = {
	triggeredFrom: string;
	isBasket?: boolean;
	isOrderDetails?: boolean;
	isConformation?: boolean;
	isMiniBasket?: boolean;
	isFetching?: boolean;
	bypassSkeleton?: boolean;
	productDetails: any;
	handleOpenClose?: (close: boolean) => void;
};

export type updateDetails = {
	productId: number;
	quantity: number;
	itemId: string;
};

const AccountProductCard = ({
	triggeredFrom = "PAGE",
	isBasket = false,
	isOrderDetails = false,
	isMiniBasket = false,
	isConformation = false,
	bypassSkeleton = false,
	isFetching,
	productDetails,
	handleOpenClose,
}: Props) => {
	const {
		ui: { isLoading },
	} = useSelector((state) => state);

	const cart = useCart();
	const { t } = useTranslation();

	const handleRemoveCartItem = () => {
		cart.removeCartItem(productDetails.id, { triggeredFrom });
	};

	const handleQuantityChange = (
		quantity: number,
		changeType: "add" | "remove",
	) => {
		const { id, product_id } = productDetails;
		cart.updateCartItem(id, product_id, quantity, changeType, {
			triggeredFrom,
		});
	};

	const hasSalePrice =
		(productDetails.sale_price &&
			(productDetails.sale_price !== productDetails.list_price ||
				productDetails.sale_price !== productDetails.original_price)) ||
		false;

	const AddedToBasket: any = Cookies.get(COOKIE_PRODUCT_GROUP);

	type CookieData = {
		sku: string;
		groupId: string;
	}[];

	const getGroupIdFromCookieData = (
		productSku: string,
		cookieData: CookieData,
	) => {
		const baseSku = productSku.split("/")[0];
		const match = cookieData.find((item: any) => item.sku === baseSku);
		return match ? match.groupId : "";
	};

	const groupId = AddedToBasket
		? getGroupIdFromCookieData(productDetails.sku, JSON.parse(AddedToBasket))
		: undefined;

	return (
		<StyledProductCard
			className={`ProductCard ${isMiniBasket ? "is-mini-basket" : ""} ${
				isConformation ? "is-basket-confirmation" : ""
			}`}
		>
			{isOrderDetails && <InputCheckbox value={true} />}

			<div className="image-wrapper">
				{!bypassSkeleton &&
				isConformation &&
				Boolean(isLoading || isFetching) ? (
					<Skeleton height={120} />
				) : (
					<LazyLoadImage src={productDetails.image_url} />
				)}
				{(isMiniBasket || isBasket) && (
					<span className="item-remove" onClick={handleRemoveCartItem}>
						{t("remove")}
					</span>
				)}
			</div>

			<div className="product-details">
				<h2>
					{isConformation ? (
						productDetails?.name || <Skeleton />
					) : (
						<Link
							prefix="/p"
							to={getProductPathFromURL(productDetails.url)}
							{...(isMiniBasket && { onClick: () => handleOpenClose?.(true) })}
						>
							{productDetails.name}
						</Link>
					)}
				</h2>
				<ul>
					<li>{productDetails?.sku || <Skeleton />}</li>

					{productDetails?.options?.map((option: any) => (
						<li key={uuid()}>
							{option.name.toLowerCase() === "size" ? t("size") : option.name}:{" "}
							{unitOfMeasure(groupId, option.value)}
						</li>
					))}

					<li>
						{t("price")}:{" "}
						{!bypassSkeleton &&
						isConformation &&
						Boolean(isLoading || isFetching) ? (
							<Skeleton width={50} />
						) : (
							<div className="price-container">
								{hasSalePrice ? (
									<Typography
										className="price price--sale"
										variant="body"
										size="small"
									>
										<Price price={Number(productDetails.sale_price)} />
									</Typography>
								) : null}
								<Typography
									className={`price${hasSalePrice ? " price--hasSale" : ""}`}
									variant="body"
									size="small"
								>
									<Price
										price={
											hasSalePrice
												? Number(productDetails.original_price)
												: Number(productDetails.list_price)
										}
									/>
								</Typography>
							</div>
						)}
					</li>

					<li className="total-quantity-wrapper">
						{isOrderDetails || isConformation ? (
							<span>
								{t("quantity")}:{" "}
								{productDetails?.quantity || <Skeleton width={50} />}
							</span>
						) : (
							<QuantityChanger
								value={productDetails.quantity}
								onChange={handleQuantityChange}
								showSkeleton={Boolean(
									(isLoading || isFetching) && !bypassSkeleton,
								)}
								className={isMiniBasket ? "is-mini-basket" : ""}
							/>
						)}

						<span className="price-total">
							{!bypassSkeleton && (isLoading || isFetching) ? (
								<Skeleton width={"10ch"} />
							) : (
								<Price
									price={
										Number(productDetails.extended_sale_price) ||
										Number(productDetails.extended_list_price) ||
										0
									}
								/>
							)}
						</span>
					</li>
				</ul>
			</div>
		</StyledProductCard>
	);
};

export default AccountProductCard;
