import { Input } from "components/common/form/Input/Input";
import Button from "components/common/ui/Button/Button";
import TextButton from "components/common/ui/Button/TextButton";
import Typography from "components/common/ui/Typography/Typography";
import styled from "styled-components";
import { remCalc } from "utilities/styles";
import {
	accountDarkGrey,
	accountLightGrey,
	grey
} from "utilities/variables/colors";
import { Roboto } from "utilities/variables/fonts";
import { aboveMedium } from "utilities/variables/media";

export const StyledWrapper = styled.div<{ isOpen: boolean }>`
	display: ${(props) => !props.isOpen && "none"};
	position: absolute;
	width: 100%;
	height: 100%;
`;

export const StyledOverlay = styled.div`
	background-color: rgba(33, 37, 41, 0.9);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	@media ${aboveMedium} {
		align-items: center;
	}
`;

export const StyledContainer = styled.div`
	position: relative;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	padding: 34px 16px 32px;
	width: 100%;

	@media ${aboveMedium} {
		padding: 64px;
		max-width: 634px;
	}
`;

export const StyledHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		position: absolute;
		margin: 16px;
		top: 0;
		right: 0;
	}
`;

export const StyledImageContainer = styled.div`
	display: none;

	@media ${aboveMedium} {
		display: block;
		width: 128px;
		margin-right: 24px;
	}
`;

export const StyledContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 24px;
`;

export const StyledHeadingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 14px;

	@media ${aboveMedium} {
		gap: 8px;
	}
`;

export const StyledHeading = styled(Typography)`
	font: bold 16px/20px ${Roboto};
	text-transform: uppercase;
	letter-spacing: 0.8px;

	@media ${aboveMedium} {
		flex: 1;
	}
`;

export const StyledSubtitle = styled(Typography)`
	font: normal 14px/17px ${Roboto};
	color: ${accountDarkGrey};
`;

export const StyledMeasurementsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;

	.multi-recommendations-text {
		color: ${accountDarkGrey};

		span {
			text-decoration: underline;
			cursor: pointer;
			transition: color 0.2s ease;

			&:hover {
				color: #737980;
			}
		}
	}
`;

export const StyledRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
`;


export const StyledRowWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
`;

export const StyledInput = styled(Input)`
	flex: 1;

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
`;

export const StyledButton = styled(Button)`
	width: 100%;

	@media ${aboveMedium} {
		width: fit-content;
		padding: 12px 16px;
	}

	&:disabled {
		background-color: ${grey};
		border-color: ${grey};
		cursor: not-allowed;
	}
`;

export const SizeRecommendation = styled.div`
	width: 100%;
	background-color: ${accountLightGrey};
	padding: ${remCalc(24)};
	display: flex;
	justify-content: center;

	sup {
		margin-left: -8px;
	}
`;

export const StartOverButton = styled(TextButton)`
	margin-top: ${remCalc(16)};
`;
